// Ng
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

// Own
import {
	BodyRefDirective,
	FooterRefDirective,
	HeaderRefDirective,
	templateReferences
} from '@shared/components/campaign/template-reference.directive';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { Campaign } from '@shared/components/campaign/campaign.model';
import { SeoMetaData } from '@shared/components/campaign/seo.model';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';
import { AppService } from '../../app.service';
import { CampaignService } from '@shared/components/campaign/campaign.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';

// Components
import { CampaignBaseComponent } from '@shared/components/campaign/campaign-base.component';
import { StageComponent } from '@shared/components/campaign/stage/stage.component';
import { TextTeaserComponent } from '@shared/components/campaign/text-teaser/text-teaser.component';
import { TeaserLargeComponent } from '@shared/components/campaign/teaser-large/teaser-large.component';
import { ProductTeaserMediumCampaign } from '@shared/components/campaign/product-teaser-medium/product-teaser-medium-campaign.component';
import { StageSeparatorComponent } from '@shared/components/campaign/stage-separator/stage-separator.component';
import { ProductTeaserCarouselComponent } from '@shared/components/campaign/product-teaser-carousel/product-teaser-carousel.component';
import { TeaserMediumComponent } from '@shared/components/campaign/teaser-medium/teaser-medium.component';

@Component({
	selector: 'zk-campaign',
	templateUrl: './campaign.component.html',
	styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild(HeaderRefDirective, {static: true}) header: HeaderRefDirective;
	@ViewChild(BodyRefDirective, {static: true}) body: BodyRefDirective;
	@ViewChild(FooterRefDirective, {static: true}) footer: FooterRefDirective;

	campaignHeaderComponents: CampaignBaseComponent[] = [];
	campaignBodyComponents: CampaignBaseComponent[] = [];
	campaignFooterComponents: CampaignBaseComponent[] = [];

	constructor(private _activatedRoute: ActivatedRoute,
				private _httpClient: HttpClient,
				private _translationService: TranslationService,
				private _seoService: SeoService,
                private _gaService: GoogleAnalyticsService,
				private _appService: AppService,
				private _campaignService: CampaignService,
                private _router: Router) {
	}

	ngOnInit(): void {
		const sub = this.requestCampaignData().subscribe((response: Campaign) => {
				sub.unsubscribe();
				this.setSeoInfo(response.seo);
				this.buildCampaignComponents(response);
				this._campaignService.campaignTranslations = response.translations;
				// console.log(response);
			},
			(error) => {
				sub.unsubscribe();
				console.log('Error requesting campaign data: ', error);
                this._router.navigate(['404']);
			}
		);
	}

    ngAfterViewInit(): void {
        this._gaService.trackPageView(PageName.CAMPAIGN_PAGE);
    }

	private requestCampaignData(): Observable<any> {
		const campaignId = this._activatedRoute.snapshot.params.id;
		return this._httpClient.get<any>(`api/campaign/${campaignId}/${this._translationService.currentLang}/${this._appService.appSubMode}`);
	}

	/**
	 * Split Campaign data into Header, Body, Footer objects, with its components and data
	 * and load them where they are referenced
	 **/
	private buildCampaignComponents(data: Campaign) {
		data.header.forEach((component: any) => {
			switch (component.type) {
				case 'StageImage':
					this.campaignHeaderComponents.push(new CampaignBaseComponent(StageComponent, component.items));
					break;
				case 'TextTeaserHeader':
					this.campaignHeaderComponents.push(new CampaignBaseComponent(TextTeaserComponent, component.items));
					break;
				default:
					console.log("No STAGE components available, or component not defined in FE");
					break;
			}
		});

		data.body.forEach((component: any) => {
			switch (component.type) {
				case 'TextTeaserBody':
					this.campaignBodyComponents.push(new CampaignBaseComponent(TextTeaserComponent, component.items));
					break;
				case 'TeaserLarge':
					this.campaignBodyComponents.push(new CampaignBaseComponent(TeaserLargeComponent, component.items));
					break;
				case 'ProductTeaserLarge':
					this.campaignBodyComponents.push(new CampaignBaseComponent(TeaserLargeComponent, component.items.article));
					break;
				case 'ProductTeaserMedium':
					this.campaignBodyComponents.push(new CampaignBaseComponent(ProductTeaserMediumCampaign, component.items.articles));
					break;
				case 'StageSeparator':
					this.campaignBodyComponents.push(new CampaignBaseComponent(StageSeparatorComponent, component.items));
					break;
				default:
					console.log("No BODY components available, or component not defined in FE");
					break;
			}
		});

		data.footer.forEach((component: any) => {
			switch (component.type) {
				case 'ProductTeaserCarousel':
					this.campaignFooterComponents.push(new CampaignBaseComponent(ProductTeaserCarouselComponent, component.items.articles));
					break;
				case 'MediumTeaser':
					this.campaignFooterComponents.push(new CampaignBaseComponent(TeaserMediumComponent, component.items));
					break;
				default:
					console.log("No FOOTER components available, or component not defined in FE");
					break;
			}
		});

		if (this.campaignHeaderComponents.length >= 1) this.loadComponents(this.header, this.campaignHeaderComponents);
		if (this.campaignBodyComponents.length >= 1) this.loadComponents(this.body, this.campaignBodyComponents);
		if (this.campaignFooterComponents.length >= 1) this.loadComponents(this.footer, this.campaignFooterComponents);
	}

	private loadComponents(template: templateReferences, source: CampaignBaseComponent[]) {

		const viewContainerRef = template.viewContainerRef;

		viewContainerRef.clear();

		source.forEach((component: CampaignBaseComponent) => {
			const componentRef = viewContainerRef.createComponent<any>(component.campaignComponent);
			componentRef.instance.data = component.campaignComponentData;
		})
	}

	private setSeoInfo(seoMetaData: SeoMetaData) {
		this._seoService.updatePageTitle(seoMetaData.title);
		this._seoService.updateMetaDescription(seoMetaData.description);
		this._seoService.updateOgTags(seoMetaData);
	}

	ngOnDestroy(): void {
		this._campaignService.clearTranslations();
        this._seoService.updateOgTags(null, true);
	}
}
